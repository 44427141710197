<template>
  <div class="yz-news-wrp">
    <erji-carousel></erji-carousel>
    <div class="ab-text">
      <div class="ab-title animate__bounceIn">
        <p>—— 新闻资讯 ——</p>
      </div>
      <div class="ab-desc animate__bounceIn">
        <p>了解更多新闻资讯！</p>
      </div>
    </div>
    <div class="yz-news-con" v-if="newsInfolist">
      <div
        class="yz-news-item"
        v-for="(item, index) in newsInfolist"
        :key="index"
        @click="newsDetails(item)"
      >
<!--        <router-link target="_blank"-->
<!--          :to="{ path: '/newsDetails', query: { cmsId: item.cmsId } }"-->
<!--        >-->
          <div class="yz-Img-info">
            <img :src="ImgUrl + item.cmsIco + '&type=thumb'" class="Img" />
          </div>
          <div class="yz-news-text">
            <p class="news-title">{{ item.cmsTitle }}</p>
            <p class="news-desc">{{ item.cmsDesc }}</p>
            <p class="news-time">
              时间：{{ item.createTime | dateFmt("YYYY-MM-DD") }}
            </p>
          </div>
<!--        </router-link>-->
      </div>
    </div>
	<div class="fenye">
		<el-pagination
		background
		layout="prev, pager, next"
		@current-change="current_change"
		:total="totalPages"
		:page-size="pageSize"
		:current="currentPage"
		>
		</el-pagination>
	</div>
  </div>
</template>
<script>
import { getList } from "../../apis/apis";
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data() {
    return {
      newsInfolist: [],
      ImgUrl: "//service.yzvet.com/haip-file/download?fileUrl=",
      pageSize: 5,
      pageNum: 1,
      currentPage: 1,
      totalPages: 0,
      //每页显示多少条
    };
  },
  methods: {
    //新闻列表
    async getList(page_num) {
      if (page_num == null || page_num == "") {
        page_num = "1";
      }
      let params = {
        pageSize: "5",
        pageNum: page_num,
        cmsCategory: "2",
      };
      try {
        let { data } = await getList(params);
        if (data.code == 1000) {
          this.newsInfolist = data.data.content;
          this.totalPages = data.data.totalPages;
        } else {
          this.$Message.error(data.message);
        }
      } catch (err) {
        console.log(err);
      }
    },
	//分页点击
    current_change(nowPage) {
      this.currentPage = nowPage;
      this.getList(nowPage);
    },
	// 跳转新闻
    newsDetails(item) {
      sessionStorage.setItem("headerMenuActiveName", "/newsList"); //存储当前点击的导航
      let routeData = this.$router.resolve({
        path: "/newsDetails",
        query: { cmsId: item.cmsId },
      });
      window.open(routeData.href, "_blank");
    },
},
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less">
.yz-news-wrp {
  .ab-text {
    margin-top: 40px;
    .ab-title {
      text-align: center;
      font-size: 30px;
      color: #0184d2;
    }
    .ab-desc {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: #656d78;
    }
  }
  .yz-news-con {
    width: 1200px;
    margin: 0 auto;
    .yz-news-item {
      width: 100%;
      height: 160px;
      border-bottom: 1px solid #ccc;
      padding: 20px 0px;
      cursor: pointer;
      .yz-Img-info {
        width: 260px;
        height: 160px;
        overflow: hidden;
        display: inline-block;
        float: left;
        .Img {
          width: 100%;
          height: 100%;
          transition: all 0.6s;
           &:hover{transform: scale(1.2);}
        }
      }
      .yz-news-text {
        float: left;
        width: 75%;
        display: inline-block;
        margin-left: 20px;
        p {
          text-align: left;
          line-height: 35px;
        }
        .news-title {
          font-size: 18px;
          color: #333;
          &:hover{
          font-weight: bold;
          font-size: 20px;
          }
        }
        .news-desc,
        .news-time {
          font-size: 16px;
          color:#6b7580;
        }
      }
    }
  }
  .fenye{
	  padding:30px 0;
  }
}
</style>
